import React, { useState, useEffect } from 'react';
import Header from './Components/Header'; // Adjust the path if necessary
import EventList from './Components/EventList'; 
import './App.css'; // Assuming you have a general CSS file for the app
import Footer from './Components/Footer';
import { SupabaseProvider, useSupabase } from './Components/SupabaseProvider.tsx';
import Login from './Components/Login'; // Add this import
import Search from './Components/Search';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import About from './Components/About';
import ResetPassword from './Components/ResetPassword'; // Add this import
import SubmitEvent from './Components/SubmitEvent';
import LoadingIndicator from './Components/LoadingIndicator'; // Add this import
import { useTheme } from './useTheme';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { HelmetProvider } from 'react-helmet-async';
import SEO from './Components/SEO';
import { Analytics } from "@vercel/analytics/react"
import { useImageMode } from './useImageMode';
import WelcomeModal from './Components/WelcomeModal';
import FloatingLoginButton from './Components/FloatingLoginButton'; // Import the new component

library.add(faCaretDown);

const AppContent = () => {
    const [showLogin, setShowLogin] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [searchParams, setSearchParams] = useState(null);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    const [resetEventList, setResetEventList] = useState(false);
    const [eventId, setEventId] = useState(null);
    const [loginWarningMessage, setLoginWarningMessage] = useState(null);
    const supabase = useSupabase();
    const location = useLocation();
    const [theme, toggleTheme] = useTheme();
    const [event, setEvent] = useState(null);
    const [showImages, toggleImageMode] = useImageMode();

    useEffect(() => {
        if (!supabase) {
            console.log('Waiting for Supabase client to initialize...');
            return;
        }

        const checkSession = async () => {
            try {
                const { data: { session } } = await supabase.auth.getSession();
                setUser(session?.user || null);
            } catch (error) {
                console.error("Error checking session:", error);
            } finally {
                setIsLoading(false);
            }
        };

        checkSession();

        const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
            setUser(session?.user || null);
        });

        return () => subscription.unsubscribe();
    }, [supabase]);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const id = queryParams.get('id') || queryParams.get('ID');
        if (id) {
            setEventId(id);
        } else {
            setEventId(null);
        }
    }, [location]);

    const toggleLogin = (message = null) => {
        setLoginWarningMessage(message);
        if (!user) {
            setShowLogin(!showLogin);
        }
    };

    const handleLogin = (userData) => {
        setUser(userData);
        setShowLogin(false); // Close the login panel after successful login
    };

    const handleLogout = async () => {
        if (!supabase) {
            console.error('Supabase client not initialized');
            return;
        }

        try {
            await supabase.auth.signOut();
            setUser(null);
        } catch (error) {
            console.error("Error signing out:", error);
        }
    };

    const handleSearch = (params) => {
        setSearchParams(params);
        setShowSearch(false);
    };

    const handleLogoClick = () => {
        setSearchParams({});
        setResetEventList(true);
    };

    useEffect(() => {
        const baseTitle = "StageDive Philly - Local Music Events";
        
        switch(location.pathname) {
            case '/about':
                document.title = `About Us - ${baseTitle}`;
                break;
            case '/submit-event':
                document.title = `Submit Event - ${baseTitle}`;
                break;
            default:
                document.title = baseTitle;
        }
    }, [location]);

    useEffect(() => {
        if (!supabase) {
            return;
        }

        const fetchEvent = async (eventId) => {
            try {
                const { data, error } = await supabase
                    .from('events')
                    .select('*')
                    .eq('id', eventId)
                    .single();

                if (error) throw error;
                if (data) setEvent(data);
            } catch (error) {
                console.error('Error fetching event:', error);
            }
        };

        const params = new URLSearchParams(location.search);
        const eventId = params.get('id');

        if (eventId) {
            if (window.__INITIAL_DATA__ && window.__INITIAL_DATA__.id === eventId) {
                setEvent(window.__INITIAL_DATA__);
            } else {
                fetchEvent(eventId);
            }
        }
    }, [location, supabase]);

    if (!supabase) {
        return <LoadingIndicator />;
    }

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div className={`app ${theme}`}>
            <WelcomeModal />
            <div id="app-content-wrapper">
                <Header 
                    toggleLogin={toggleLogin} 
                    isLoggedIn={!!user} 
                    onLogout={handleLogout}
                    toggleSearch={() => setShowSearch(!showSearch)}
                    user={user}
                    onLogoClick={handleLogoClick}
                    theme={theme}
                    toggleTheme={toggleTheme}
                    showImages={showImages}
                    toggleImageMode={toggleImageMode}
                />
                <main className="app-main">
                    <div className="content-wrapper">
                        {showLogin && !user && (
                            <Login 
                                onClose={() => setShowLogin(false)} 
                                onLogin={handleLogin}
                                warningMessage={loginWarningMessage}
                            />
                        )}
                        {showSearch && (
                            <Search 
                                onSearch={handleSearch}
                                onClose={() => setShowSearch(false)}
                                isDesktop={isDesktop}
                            />
                        )}
                        <Routes>
                            <Route 
                                path="/" 
                                element={
                                    <EventList 
                                        user={user} 
                                        searchParams={searchParams} 
                                        setSearchParams={setSearchParams}
                                        resetEventList={resetEventList}
                                        setResetEventList={setResetEventList}
                                        eventId={eventId}
                                        showImages={showImages}
                                        toggleLogin={toggleLogin}
                                    />
                                } 
                            />
                            <Route path="/about" element={<About />} />
                            <Route path="/reset-password" element={<ResetPassword />} />
                            <Route path="/submit-event" element={<SubmitEvent user={user} />} />
                            <Route path="/events/:eventId" element={<EventDetail event={event} />} />
                        </Routes>
                    </div>
                </main>
                <Footer onLogoClick={handleLogoClick} />
                {!user && <FloatingLoginButton toggleLogin={toggleLogin} />}
            </div>
        </div>
    );
};

const EventDetail = ({ event }) => {
    if (!event) return <div>Loading...</div>;

    return (
        <>
            <SEO 
                title={event.event_name}
                description={`${event.event_name} at ${event.venue} on ${new Date(event.date).toLocaleDateString()}`}
                type="event"
                event={event}
            />
            <div className="event-detail">
                <h1>{event.event_name}</h1>
                <p>Venue: {event.venue}</p>
                <p>Date: {new Date(event.date).toLocaleDateString()}</p>
                {event.event_details && <p>{event.event_details}</p>}
            </div>
        </>
    );
};

const App = () => (
    <Router>
        <HelmetProvider>
            <SupabaseProvider>
                <AppContent />
            </SupabaseProvider>
            <Analytics />
        </HelmetProvider>
    </Router>
);

export default App;
