import { useState, useEffect } from 'react';
import Login from '../Components/Login';
import './WelcomeModal.css';

function WelcomeModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    const hasSeenWelcome = localStorage.getItem('hasSeenWelcome');
    if (!hasSeenWelcome) {
      setIsOpen(true);
      localStorage.setItem('hasSeenWelcome', 'true');
    }
  }, []);

  useEffect(() => {
    // Apply/remove body class immediately based on isOpen, not waiting for animation
    const contentWrapper = document.getElementById('app-content-wrapper');

    if (isOpen) {
      document.body.classList.add('modal-open');
      if (contentWrapper) contentWrapper.inert = true;
    } else {
      document.body.classList.remove('modal-open');
      if (contentWrapper) contentWrapper.inert = false;
    }

    // Cleanup function to remove the class and inert attribute
    return () => {
      document.body.classList.remove('modal-open');
      if (contentWrapper) contentWrapper.inert = false;
    };
  }, [isOpen]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsOpen(false);
      setIsClosing(false);
    }, 300);
  };

  const handleLoginClick = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsOpen(false);
      setShowLogin(true);
      setIsClosing(false);
    }, 300);
  };

  const handleLoginClose = () => {
    setShowLogin(false);
  };

  const handleLogin = (userData) => {
    setShowLogin(false);
  };

  if (showLogin) {
    return <Login onClose={handleLoginClose} onLogin={handleLogin} />;
  }

  if (!isOpen && !isClosing) return null;

  return (
    <div className={`modal-overlay ${isOpen && !isClosing ? 'open' : ''} ${isClosing ? 'closing' : ''}`}>
      <div className={`welcome-modal ${isOpen && !isClosing ? 'open' : ''} ${isClosing ? 'closing' : ''}`}>
        <button className="close-button" onClick={handleClose}>
          ✕
        </button>
        <h2>Welcome to</h2>
        <h1><img src={require('../assets/lllogo.svg').default} alt="Stage Dive Philly Logo" className="welcome-logo" /></h1>
        
        <div className="modal-content">
          <div className="welcome-section">
            <h3>Why Sign up?</h3>
            <ul>
              <li>Access to Philadelphia's most comprehensive music calendar</li>
              <li>Save shows you want to attend</li>
              <li>Track your favorite venues and artists</li>
              <li>Get notified about upcoming events</li>
            </ul>
          </div>

          <button 
            className="cta-button"
            onClick={handleLoginClick}
          >
            Login or Sign Up Now
          </button>
        </div>
      </div>
    </div>
  );
}

export default WelcomeModal; 